.contact-wrap {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    text-align: left;
    padding: 0 5px;
}
.contact-form {
    width: 500px;
    flex: 1 1 auto;
}
.container-xs-contact {
    width: auto !important;
    margin: 0 !important;
}
.contact-header {
    padding-bottom: 15px;
    text-align: left;
}
/* end - контакты */

/* start - главный блок */
.hero-inner-title, .hero-inner-contact {
    padding-top: 80px !important;
}
.title-custom {
    display: block;
    font-size: 35px;
}
.titles-custom {
    display : flex;
}
.titles-custom-text {
    margin: 0 auto;
    text-align: left;
}
.titles-custom-text > p {
    display: block;
    font-weight: 800;
}
.titles-custom-text > span {
    display: block;
    margin-bottom: 14px;
}
.titles-custom-image {
    margin: 0;
    margin-left: auto;
    padding: 20px;
    padding-left: 30px;
    max-height: 300px;
}
.titles-custom-btn {
    margin-top: 80px;
}
@media (max-width: 896px) {
    .titles-custom-image{
        display: none;
    }
}
@media (max-width: 620px) {
    .titles-custom-btn {
        margin-top: 51px;
    }
}
/* end - главный блок */

.tiles-item-price-example {
    margin-top: 15px !important;
}

.cost-text {
    display: block;
    margin-top: 60px;
    text-align: center;
}

