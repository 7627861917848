.custom-section-header {
    color: #3d4744 !important;
}

.custom-footer-btn {
    background-color: #eceded !important;
}

.custom-footer-btn:hover {
    background-color: #abc5c5 !important;
}

.custom-features-tiles-item-image {
    width: 100px !important;
    height: 100px !important;
}

.custom-btn {
    color: white !important;
}

.custom-color-text {
    color: white !important;
}

.custom-tiles-item-inner {
    background: white !important;
}

.text-align-center {
    text-align: center !important;
}

/* start - для меньше чем width: 400 */
@media (max-width: 400px) { 
    .h1-mobile-font-size {
        font-size: 40px !important;
    }
    .title-custom {
        font-size: 28px;
    }
}
/* end - для меньше чем width: 400 */